import React from "react";
import { Grid, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ModuleCard from "../Template/ModuleCard";
import { useSelector } from "react-redux";


const CoursePreview = ({ formData, selectedModules }) => {
  const selectedTemplatesFromRedux = useSelector(state => state.template);
  console.log("Course Preview start");
  console.log(formData)
  console.log(selectedModules)
  console.log(selectedTemplatesFromRedux)
  console.log(selectedTemplatesFromRedux[selectedModules[0].templateId])
  console.log("Course Preview end");
  
  let estimatedTime = 0;
  selectedModules.forEach((element) => estimatedTime += element.totalTime);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ textAlign: "center" }}>
          {formData.courseName}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>Kursbeskrivelse: {formData.courseDetails}</Typography>
      </Grid>
      <Grid item xs={12} container justifyContent="space-between" alignItems="center" sx={{mt:2}}>
        <Grid item xs={4} container direction="row" alignItems="center" spacing={1}>
          <Grid item>
            <AssessmentIcon sx={{ color: "#707070" }} />
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: 13, color: "#707070" }}>
            {selectedModules.length} modul(er)
            </Typography>
          </Grid>
        </Grid>
        <Grid item  xs={4} container alignItems="center" spacing={1}>
          <Grid item>
            <AccessTimeIcon sx={{ color: "#707070" }} />
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: 13, color: "#bdbdbd" }}>
              {estimatedTime} min
            </Typography>
          </Grid>
        </Grid>
        <Grid item  xs={4} container alignItems="center" spacing={1}>
          <Grid item>
            <CalendarMonthIcon sx={{ color: "#707070" }} />
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: 14, color: "#707070" }}>
              Opprettet: {new Date().toLocaleDateString("no-No")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
  {selectedModules.map((module, index) => (
  <Grid item xs={6} sm={4} lg={3} key={module.id} sx={{mt:2}}>
    <ModuleCard 
      selectedModules={selectedModules} 
      activeModuleIndex={index + 1} 
      selectedTemplatesFromRedux={selectedTemplatesFromRedux}
      imageHeight={130}
      width={200}
    />
  </Grid>
))}
    </Grid>
  );
};

export default CoursePreview;
