import React from 'react';
import { Grid, Box, Typography } from "@mui/material";
import { useGetModulesBelongedToOrgQuery } from '../../../../../services/Module/moduleSlice';
import ModuleCard from './ModuleCard';
import Loading from '../../../../../layout/Loading';

const ModuleSelectionModal = ({onModulesChange, selectedModules, activeModuleIndex }) => {
    const { data, isLoading} = useGetModulesBelongedToOrgQuery();
    
    const handleModuleSelect = (module) => {
        let newSelectedModules;
        if (module.isSelected) {
            newSelectedModules = [...selectedModules, module];
        } else {
            newSelectedModules = selectedModules.filter(m => m.id !== module.id);
        }
        onModulesChange(newSelectedModules); // Pass changes 
        console.log("Selected new modules.");
    };
    
    return (
        <Box component="form" sx={{mb:1}}>
        {
            isLoading ? 
            <Box display="flex" flexDirection="column" alignItems="center">
            <Loading size={30} color={"#5177b8"}/> 
            <Typography sx={{mt:2}} variant="body2" color='secondary'> Laster inn modulene…</Typography>
        </Box>
           
            : (
                <Grid container rowSpacing={3} columnSpacing={3} maxWidth="xl">
                {data?.data.map((module, index) => (
                    <Grid item xs={6} sm={4} md={3} key={index}>
                        <ModuleCard moduleData={module}  
                        onSelect={handleModuleSelect}  
                        isSelected={selectedModules.some(m => m.id === module.id)}
                        activeModuleIndex={activeModuleIndex}
/>
                    </Grid>
                ))}
            </Grid>
            )
        }
    </Box>
    );
}

export default ModuleSelectionModal;
