import { React, useState, useEffect, useCallback } from "react";
import {
  Paper,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  Box,
  Button,
  FormControl,
  Stack,
  FormHelperText,
} from "@mui/material";
import UserSelect from "./UserSelect";
import CourseSelect from "./CourseSelect";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { successNote, toastErrorNotify } from "../../../helpers/toastNotify";
import Loading from "../../../layout/Loading";
import {
  AddScheduleByDepartmentAdmin,
  AddScheduleByUpperAdmin,
  GetCustomText,
} from "../../../api/endpoints";
import CustomTextField from "./CustomTextField";
import DepartmentUserSelect from "./DepartmentUserSelect";
import {scheduledCourseApi} from "../../../services/ScheduledCourse/scheduledCourseApi";
import { useDispatch } from "react-redux";
import apiClient from "../../../api/apiClient";
import { useNavigate } from "react-router-dom";


const CoursePlanning = () => {
  const [courseValue, setCourseValue] = useState(null);
  const [userValue, setUserValue] = useState(null);
  const [startValue, setStartValue] = useState(dayjs());
  const [endValue, setEndValue] = useState(dayjs().add(1, "day"));
  const [loading, setLoading] = useState(false);
  const [customText, setCustomText] = useState("");
  const [isCustomTextEditing, setIsCustomTextEditing] = useState(false);
  const [smsValue, setSmsValue] = useState(false);
  const [smsText, setSmsText] = useState(null);
  const [emailValue, setEmailValue] = useState(false);
  const [emailText, setEmailText] = useState(null);
  const role = localStorage.getItem("roles");
  const roleFromLocal = JSON.parse(role);
  const [formSubmit, setFormSubmit] = useState(false);
  const [reset, setReset] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // console.log("reset", reset);
  // console.log("planning", courseValue);
  // console.log(smsValue, emailValue)
  // console.log("customText", customText);
  // console.log("courseValue", courseValue);
  // console.log('userValue', userValue)
  // console.log("startValue", startValue.format("YYYY-MM-DD"));
  // console.log("endValue", endValue.format("YYYY-MM-DD"));

  const handleChange = (newValue) => {
    setStartValue(newValue);
    if (endValue.isBefore(newValue)) {
      setEndValue(newValue.add(1, "day"));
    }
  };

  const handleChange2 = (newValue) => {
    setEndValue(newValue);
  };

  // ! Get ModulID and TemplateID from child component

  const handleCourseSelect = (courseValue) => {
    setCourseValue(courseValue);
    setCustomText(customText);
  };

  const handleUserSelect = (selectedBox) => {
    const data = selectedBox.map((item) => item.id);
    setUserValue(data);
  };

  // ! Schedule Course API call

  const isUpperAdmin = roleFromLocal.some((role) =>
    ["org.admin.super", "org.admin.assistant", "org.admin.course"].includes(
      role
    )
  );

  //! create isEmptyForm
  function isEmptyForm() {
    return (
      userValue?.length === 0 ||
      courseValue === "" ||
      (!emailValue && !smsValue) ||
      !startValue ||
      !endValue
    );
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    //! Hvis skjemaets innhold er tomt, vis feilmeldingen og avslutt prosessen
    if (isEmptyForm()) {
      toastErrorNotify("Vennligst fyll ut de tomme feltene i skjemaet");
      setFormSubmit(true);
      setLoading(false);
      return;
    }

    let params = {
      endUserIdList: userValue,
      courseId: courseValue,
      startDateTime: startValue.format("YYYY-MM-DD"),
      endDateTime: endValue.format("YYYY-MM-DD"),
      emailText: emailText,
      smsText: smsText,
    };
    // console.log('param', params)
    let endpoint;

    if (isUpperAdmin) {
      endpoint = AddScheduleByUpperAdmin;
    } else {
      endpoint = AddScheduleByDepartmentAdmin;
    }

    try {
      const response = await apiClient.post(endpoint, params);
      if (response.status === 200) {
        // console.log(response.data);
        successNote("Kurset er planlagt");
        setReset(true);
        setFormSubmit(false)
        setCourseValue(null);
        setEmailValue(false);
        setSmsValue(false);
        setStartValue(dayjs());
        setEndValue(dayjs().add(1, "day"));
      navigate("/kurs/planlagte-kurs");
           // !Invalidate the 'ScheduledCourses' tag, to get the new scheduled course data
      dispatch(scheduledCourseApi.util.invalidateTags(["ScheduledCourses"]));
        return;
      }
      else {
        if (response?.Message) {
          toastErrorNotify(response.Message);
        } else {
          toastErrorNotify("Mislyktes")
        }
      }
    } catch (err) {
      if (!err.response) {
        toastErrorNotify("Ingen serverrespons");
      } else if (err?.response.data?.data) {
        toastErrorNotify(err?.response.data.data?.validationErrors[0]);
      } else if (err.response.data) {
        toastErrorNotify(err.response.data?.message);
      } else {
        toastErrorNotify("Mislyktes");
      }
      console.log(err.response.data);
    } finally {
      // console.log('params', params)
      setLoading(false);
    }
  }

  useEffect(() => {
    setSmsValue(false);
    setEmailValue(false);
  }, [formSubmit]);

  // ! Handle Checkboxes

  const handleCheckboxChange1 = (e) => {
    setSmsValue(e.target.checked);
  };

  const handleCheckboxChange2 = (e) => {
    setEmailValue(e.target.checked);
  };

  // ! To show and edit the custom textfield

  const getCustomText = useCallback(async () => {
    const params = {
      courseId: courseValue,
    };
    try {
      const response = await apiClient.post(GetCustomText, params);
      setCustomText(response.data.data.message);
    } catch (e) {
      console.log(e?.response.data);
    }
  }, [courseValue]);
  function handleCustomTextEditing() {
    setIsCustomTextEditing(true);
  }

  function handleCustomTextDoneEditing() {
    setIsCustomTextEditing(false);
  }

  useEffect(() => {
    if (courseValue > 0 && !isCustomTextEditing) getCustomText();
    if (smsValue) setSmsText(customText);
    else setSmsText("blank");
    if (emailValue) setEmailText(customText);
    else setEmailText("blank");
  }, [
    courseValue,
    isCustomTextEditing,
    smsValue,
    emailValue,
    customText,
    getCustomText,
  ]);


  return (
    <Grid container>
      <Paper sx={{ m: 2, p: 3 }}>
        <Typography variant="h5" align="center" sx={{ mb: 4 }}>
          Planlegg Kurs
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            maxWidth="md"
          >
            {/* // ! Course Select Component */}

            <CourseSelect
              handleCourseSelect={handleCourseSelect}
              formSubmit={formSubmit}
              reset={reset}
              setReset={setReset}
            />

            {/* // ! User and Department Autocomplete Component */}

            <Grid item xs={12}>
              {isUpperAdmin ? (
                <UserSelect
                  handleUserSelect={handleUserSelect}
                  formSubmit={formSubmit}
                  reset={reset}
                  setReset={setReset}
                />
              ) : (
                <DepartmentUserSelect
                  handleUserSelect={handleUserSelect}
                  formSubmit={formSubmit}
                  reset={reset}
                  setReset={setReset}
                />
              )}
            </Grid>

            {/* // ! Start and Finish Date */}
            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Startdato"
                    inputFormat="DD.MM.YYYY"
                    disablePast
                    value={startValue}
                    onChange={handleChange}
                    renderInput={(params) => <TextField {...params} required />}
                  />
                  <DesktopDatePicker
                    label="Sluttdato"
                    inputFormat="DD.MM.YYYY"
                    minDate={startValue}
                    disablePast
                    required
                    value={endValue}
                    onChange={handleChange2}
                    renderInput={(params) => <TextField {...params} required  />}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">Invitasjon</Typography>
              <Typography>
                Send invitasjon til kursdeltakere med SMS og /eller e-post.
                Trykk på tekstfeltet for å redigere invitasjonen.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {/* // ! Editable Text Field */}

              <CustomTextField
                value={
                  courseValue
                    ? customText
                    : "Du må velge kurs for å sende invitasjon"
                }
                customText={customText}
                setCustomText={setCustomText}
                onCustomTextEditing={handleCustomTextEditing}
                onCustomTextDoneEditing={handleCustomTextDoneEditing}
                getCustomText={getCustomText}
                courseValue={courseValue}
              />
            </Grid>

            {/* // ! Checkbox for SMS/ Email */}
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  my: 3,
                  px: 1,
                }}
              >
                <FormControl>
                  <Stack direction="row">
                    <FormControlLabel
                      control={<Checkbox />}
                      label="SMS"
                      checked={smsValue}
                      onChange={handleCheckboxChange1}
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="E-post"
                      checked={emailValue}
                      onChange={handleCheckboxChange2}
                    />
                  </Stack>
                </FormControl>
                <Button
                  variant="contained"
                  className="PrimaryButton"
                  type="submit"
                  sx={{ height: 40, width: 180 }}
                  disabled={loading}
                >
                  {loading ? (
                    <Loading color={"#fff"} size={25} />
                  ) : (
                    "Send Invitasjon"
                  )}
                </Button>
              </Box>
              {formSubmit && !emailValue && !smsValue && (
                <FormHelperText sx={{ ml: 1 }} error>
                  Vennligst velg minst ett alternativ
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};

export default CoursePlanning;
