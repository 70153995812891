import React from "react";
import {
  Card, Box,
  Chip,
  CardActionArea,
  CardContent,
  Typography,
  CardMedia,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { getModuleTypeColor } from "../../../../../utils/displayUtils";
import { moduleNameFromType } from "../../../../../utils/displayUtils";

export default function ModuleCard({
  selectedModules,
  activeModuleIndex,
  selectedTemplatesFromRedux, width, imageHeight
}) {
  const activeModule = selectedModules[activeModuleIndex - 1];
  // console.log('selectedModule', selectedTemplatesFromRedux[activeModuleIndex])

  const malArray = [];
  activeModule.totalTime = 0;
  if(activeModuleIndex)
  {
    if (selectedTemplatesFromRedux[activeModuleIndex]?.trainingIsActive) {
      malArray.push("Trening");
      activeModule.totalTime = activeModule.trainingEstimatedTime;
    }
    if (selectedTemplatesFromRedux[activeModuleIndex]?.testIsActive) {
      malArray.push("Test");
      activeModule.totalTime = +activeModule.totalTime + +activeModule.testEstimatedTime;
    }
    if (selectedTemplatesFromRedux[activeModuleIndex]?.theoryIsActive) {
      malArray.push("Teori");
      activeModule.totalTime = +activeModule.totalTime + +activeModule.teoriEstimatedTime;
    }
  }

  const malString = malArray.length > 0 ? malArray.join(" ") : "-";

  return (
    <Card sx={{ width:{width}}}>
      <CardActionArea>
        <CardMedia
          component="img"
          height={imageHeight}
          image={activeModule?.image || ""}
          alt={activeModule?.name || ""}
        />
        <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" px={2} py={1}>
            <Typography sx={{ fontWeight: 600, color: "#707070", fontSize: 14 }}>
                {activeModule?.name}
            </Typography>
            <AccessTimeIcon fontSize="small" sx={{ color: "#707070" }}/>
        </Box>
          <Chip
            label={`${moduleNameFromType(activeModule?.moduleType)}`}
            sx={{
              backgroundColor: `${getModuleTypeColor(activeModule?.moduleType)}`,
              color: "#fff",
              fontSize: 11,
              mb: 1,
            }}
          />
          <Typography
            sx={{ fontWeight: 600, color: "#616161", my: 1, fontSize: 14 }}
          >
            {selectedTemplatesFromRedux[activeModuleIndex]?.name}
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            Mal : {malString}
          </Typography>
          <Typography
            variant="caption"
            display="block"
            sx={{ fontSize: 12, color: "#757575" }}
          >
            Krav til godkjent trening:{" "}
            {selectedTemplatesFromRedux[activeModuleIndex]?.trainingIsActive
                ? selectedTemplatesFromRedux[activeModuleIndex]?.generalSuccessPercentage 
                    ? selectedTemplatesFromRedux[activeModuleIndex]?.generalSuccessPercentage + "%"
                    : " -"
                : " -"
            }
          </Typography>
          <Typography
            variant="caption"
            display="block"
            sx={{ fontSize: 12, color: "#757575" }}
          >
            Krav til godkjent test:{" "}
            {selectedTemplatesFromRedux[activeModuleIndex]?.testIsActive
                ? selectedTemplatesFromRedux[activeModuleIndex]?.testApprovalPercentage 
                    ? selectedTemplatesFromRedux[activeModuleIndex]?.testApprovalPercentage + "%"
                    : " -"
                : " -"
            }
          </Typography>
          <Typography
            variant="caption"
            display="block"
            sx={{ fontSize: 12, color: "#757575" }}
          >
            Antall pasienter:{" "}
            {selectedTemplatesFromRedux[activeModuleIndex]?.patientCount
              ? selectedTemplatesFromRedux[activeModuleIndex]?.patientCount
              : " -"}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
