import React from 'react';
import {
    Card,
    Chip,
 CardActionArea,
    CardContent,
    Typography, CardMedia
  } from "@mui/material";
import { CheckCircle } from '@mui/icons-material';
import { useSelector } from "react-redux";
import { getModuleTypeColor } from "../../../../../utils/displayUtils";
import { moduleNameFromType } from "../../../../../utils/displayUtils";


const FysiskHelse = 'https://res.cloudinary.com/dge9ers9x/image/upload/v1693381798/Making%20View%20Assets/fysisk2_cpgau1.png'
const FysiskHelse2 = 'https://res.cloudinary.com/dge9ers9x/image/upload/v1696934855/Making%20View%20Assets/fysiskhelse_lbrpoe.png'
const ABCDE = 'https://res.cloudinary.com/dge9ers9x/image/upload/v1696934828/Making%20View%20Assets/abcde_yvvrdj.png'
const NEWS2= 'https://res.cloudinary.com/dge9ers9x/image/upload/v1693381807/Making%20View%20Assets/fysiskBilde_qso9s4.png'
const MentalHelse ='https://res.cloudinary.com/dge9ers9x/image/upload/v1696935724/Making%20View%20Assets/mental1_ime6ue.png'
const Mental2 = 'https://res.cloudinary.com/dge9ers9x/image/upload/v1693381815/Making%20View%20Assets/mentalBilde_bznya1.png'
const Mental3 = 'https://res.cloudinary.com/dge9ers9x/image/upload/v1693381818/Making%20View%20Assets/voldOgTrusler_if5zl3.png'

const images = [
    { moduleName: 'ABCDE', url: ABCDE },
    { moduleName: 'NEWS2', url: NEWS2 },
    { moduleName: 'Broset', url: Mental3 },
    { moduleName: 'BVC', url: MentalHelse },
    { moduleName: 'Egen sikkerhet', url: Mental2 },
    { moduleName: 'ISBAR', url: FysiskHelse },
    { moduleName: 'Tidlig tegn', url: Mental3 },
    { moduleName: 'Deeskalering', url: MentalHelse},
    { moduleName: 'Førstehjelp', url: FysiskHelse },
    { moduleName: 'Refleksjonrom', url: Mental2 },
    { moduleName: 'Fult løp', url: FysiskHelse2 },
    { moduleName: 'HLR m/ hjertestarter', url: FysiskHelse2 },
    { moduleName: 'HLR uten hjertestarter', url: ABCDE },
    { moduleName: 'Pasientundersøkelsen', url: NEWS2 },
  ];

export default function ModuleCard({ moduleData, onSelect, isSelected, activeModuleIndex }) {
    const moduleImage = images.find(img => img.moduleName === moduleData.name)?.url || '';
    const selectedTemplatesFromRedux = useSelector(state => state.template);
    
    const handleCardClick = () => {
        const newSelectedState = !isSelected;
        onSelect({
            id: moduleData.id,
            name: moduleData.name,
            moduleType: moduleData.moduleType,
            image: moduleImage || moduleData.image,
            isSelected: newSelectedState,
            templateId: selectedTemplatesFromRedux[activeModuleIndex],
            teoriEstimatedTime: moduleData.teoriEstimatedTime,
            testEstimatedTime: moduleData.testEstimatedTime,
            trainingEstimatedTime: moduleData.trainingEstimatedTime,
            totalTime: 0
        });
    };

// console.log('moduler', moduleData,)

  return (
    <Card sx={{ width:160, height:200 }}>
      <CardActionArea onClick={handleCardClick}>
        <CardMedia
          component="img"
          height="100"
          image={moduleImage || moduleData.image}
          alt={moduleData.name} 
        />
        <CardContent>
    <Typography sx={{fontWeight:600, color:"#616161", mb:1, fontSize:13}}>
        {moduleData.name}
    </Typography>
                  <Chip label={`${moduleNameFromType(moduleData.moduleType)}`} sx={{ backgroundColor: `${getModuleTypeColor(moduleData.moduleType)}`, color:'#fff', fontSize:11}} />
</CardContent>
{isSelected && ( 
                    <CheckCircle color="success" sx={{ position: 'absolute', bottom: 5, right: 5 }} />
                )}
      </CardActionArea>
    </Card>
  );
}
